import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';
import { 
  DataGridPro,
  GridToolbar,
  } from '@mui/x-data-grid-pro';

export default function CurrentWarehouseTable(props) {
    // !new code
    const userData = useContext(UserContext)
    const visibleColumns = {
      id: false,
 
    }

    const nonAdminUser = userData.userRole !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentWarehouses  = {
      columns: [
      {field: 'id', hide: true},
      // {field: 'accountName', headerName: 'Account', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'warehouseName', headerName: 'Warehouse', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'warehouseNumber', headerName: 'Warehouse Number', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'address', headerName: 'Address', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'city', headerName: 'City', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'state', headerName: 'State', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'zip', headerName: 'Zip', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'market', headerName: 'Market', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'region', headerName: 'Region', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'description', headerName: 'Location Description', width:300,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'inventoryCount', headerName: 'Inventory', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'descrepanciesCount', headerName: 'Discrepancies', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'type', headerName: 'Warehouse Type', width:275, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'lat', headerName: 'Lat',  width:150, editable: false, headerAlign: 'center',  align: 'center'},
      {field: 'lon', headerName: 'Lon',  width:150, editable: false, headerAlign: 'center',  align: 'center'},
      // {field: 'actions', type: 'actions', headerName: 'Delete Warehouse', width:150 ,  
      // getActions: (params) => [
      //   <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteWarehouse(params.id)}  label="Delete" />
      // ]},
      ], 
      rows: props.currentWarehousesDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
          sx={dataGridStyles.root}
          pagination
          initialState={{
            pageSize: 10,
            columns: {
              columnVisibilityModel: visibleColumns,
            },
            pinnedColumns: {left: ['warehouseName']},
            }}
          slots={{toolbar: GridToolbar}}
        
        {...currentWarehouses}  
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}

      />
    </div>

  );
};